import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
  LOCALE_ID,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { provideTransloco } from '@jsverse/transloco';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import {
  NgxsRouterPluginModule,
  RouterStateSerializer,
} from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { Apollo, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { MessageService } from 'primeng/api';

import {
  LoggingService,
  spinnerInterceptor,
  SpinnerService,
} from '@customer-portal/core';
import { DocumentsState } from '@customer-portal/data-access/documents';
import { environment } from '@customer-portal/environments';
import { PreferenceState } from '@customer-portal/preferences';
import { CustomRouterStateSerializer } from '@customer-portal/router';
import {
  Language,
  LocaleService,
  registerLocales,
} from '@customer-portal/shared';

import { appRoutes } from './app.routes';
import { errorInterceptor } from './error.interceptor';
import { GlobalErrorHandler } from './global.error.handler';
import { LanguageStorageService } from './services';
import { TranslocoHttpLoader } from './transloco-http.loader';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const loggingService = new LoggingService();

  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      loggingService.logTrace(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) {
    loggingService.logTrace(`[Network error]: ${networkError}`);
  }
});

registerLocales();

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([errorInterceptor, spinnerInterceptor])),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideRouter(appRoutes),
    provideAnimations(),
    provideHttpClient(),
    importProvidersFrom(
      NgxsModule.forRoot([DocumentsState, PreferenceState], {
        developmentMode: !environment.production,
      }),
      NgxsDispatchPluginModule.forRoot(),
      NgxsReduxDevtoolsPluginModule.forRoot(),
      NgxsRouterPluginModule.forRoot(),
    ),
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: [Language.English, Language.Italian],
        defaultLang: LanguageStorageService.getLanguage(),
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: (localeService: LocaleService) => () =>
        localeService.setLocale(LanguageStorageService.getLanguage()),
      deps: [LocaleService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService): string =>
        localeService.getLocale(),
      deps: [LocaleService],
    },
    {
      provide: APOLLO_NAMED_OPTIONS, // <-- Different from standard initialization
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          default: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([
              errorLink,
              httpLink.create({
                uri: environment.graphqlHost,
              }),
            ]),
            defaultOptions: {
              query: {
                errorPolicy: 'ignore',
              },
            },
          },
          audit: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([
              errorLink,
              httpLink.create({
                uri: environment.auditGraphqlHost,
              }),
            ]),
            defaultOptions: {
              query: {
                errorPolicy: 'ignore',
              },
            },
          },
          newAudit: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([
              errorLink,
              httpLink.create({
                uri: environment.newAuditGraphqlHost,
              }),
            ]),
            defaultOptions: {
              query: {
                errorPolicy: 'ignore',
              },
            },
          },
          finding: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([
              errorLink,
              httpLink.create({
                uri: environment.findingGraphqlHost,
              }),
            ]),
            defaultOptions: {
              query: {
                errorPolicy: 'ignore',
              },
            },
          },
          certificate: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([
              errorLink,
              httpLink.create({
                uri: environment.certificateGraphqlHost,
              }),
            ]),
            defaultOptions: {
              query: {
                errorPolicy: 'ignore',
              },
            },
          },
          newCertificate: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([
              errorLink,
              httpLink.create({
                uri: environment.newCertificateGraphqlHost,
              }),
            ]),
            defaultOptions: {
              query: {
                errorPolicy: 'ignore',
              },
            },
          },
          schedule: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([
              errorLink,
              httpLink.create({
                uri: environment.scheduleGraphqlHost,
              }),
            ]),
            defaultOptions: {
              query: {
                errorPolicy: 'ignore',
              },
            },
          },
          contracts: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([
              errorLink,
              httpLink.create({
                uri: environment.contractsGraphqlHost,
              }),
            ]),
            defaultOptions: {
              query: {
                errorPolicy: 'ignore',
              },
            },
          },
          invoice: {
            cache: new InMemoryCache(),
            link: ApolloLink.from([
              errorLink,
              httpLink.create({
                uri: environment.invoicesGraphqlHost,
              }),
            ]),
            defaultOptions: {
              query: {
                errorPolicy: 'ignore',
              },
            },
          },
        };
      },
      deps: [HttpLink],
    },
    Apollo,
    SpinnerService,
    MessageService,
  ],
};
