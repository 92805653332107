import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  output,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';

import { UnreadActionsStoreService } from '@customer-portal/data-access/actions';
import { UnreadNotificationsStoreService } from '@customer-portal/data-access/notifications';

import { NavbarButtonComponent } from '../navbar-button/navbar-button.component';
import { NavbarSearchComponent } from '../navbar-search/navbar-search.component';
import { NavbarSettingsComponent } from '../navbar-settings/navbar-settings.component';

@Component({
  selector: 'customer-portal-navbar',
  imports: [
    CommonModule,
    TranslocoDirective,
    NavbarButtonComponent,
    NavbarSearchComponent,
    NavbarSettingsComponent,
  ],
  providers: [UnreadNotificationsStoreService, UnreadActionsStoreService],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  public toggleSidebarEvent = output<boolean>();

  constructor(
    private readonly router: Router,
    public unreadNotificationsStoreService: UnreadNotificationsStoreService,
    public unreadActionsStoreService: UnreadActionsStoreService,
  ) {}

  ngOnInit(): void {
    this.unreadNotificationsStoreService.loadUnreadNotifications();
    this.unreadActionsStoreService.loadUnreadActions();
  }

  onToggleSidebar(value: boolean): void {
    this.toggleSidebarEvent.emit(value);
  }

  onNavigateTo(route: string): void {
    this.router.navigate([`/${route}`]);
  }

  onActionsClick(route: string): void {
    this.router.navigate([`/${route}`]);
  }
}
